html {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

body {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
  line-height: 1;
}

div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

blockquote {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  quotes: none;
  border: 0;
  margin: 0;
  padding: 0;
}

blockquote:before, blockquote:after {
  content: "";
  content: none;
}

pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

q {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  quotes: none;
  border: 0;
  margin: 0;
  padding: 0;
}

q:before, q:after {
  content: "";
  content: none;
}

s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

ol, ul {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
  list-style: none;
}

li, fieldset, form, label, legend {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

table {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

caption, tbody, tfoot, thead, tr, th, td {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
}

canvas {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

details {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
}

embed {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

figure, figcaption, footer, header, hgroup, menu, nav {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
}

output, ruby {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

section {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
}

summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

/*# sourceMappingURL=index.bca7e9e0.css.map */
